<template>
  <v-dialog
    :value="dialog"
    @click:outside="cancelDelete"
    max-width="600"
    scrollable
  >
    <v-card>
      <v-btn icon @click="cancelDelete" class="close-button">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text v-html="message"></v-card-text>
      <v-card-actions>
        <v-btn color="error" text @click="confirmDelete">{{
          buttonText
        }}</v-btn>
        <v-btn text @click="cancelDelete">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.v-dialog .v-card__text {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
</style>

<script>
export default {
  props: {
    dialog: Boolean,
    message: {
      type: String,
      required: false,
      default: "Are you sure you want to delete this item?",
    },
    title: {
      type: String,
      required: false,
      default: "Delete Confirmation",
    },
    buttonText: {
      type: String,
      required: false,
      default: "Delete",
    },
  },
  methods: {
    confirmDelete() {
      this.$emit("delete");
    },
    cancelDelete() {
      this.$emit("cancel");
    },
  },
};
</script>
