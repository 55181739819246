import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.dialog,"max-width":"600","scrollable":""},on:{"click:outside":_vm.cancelDelete}},[_c(VCard,[_c(VBtn,{staticClass:"close-button",attrs:{"icon":""},on:{"click":_vm.cancelDelete}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))]),_c(VCardText,{domProps:{"innerHTML":_vm._s(_vm.message)}}),_c(VCardActions,[_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":_vm.confirmDelete}},[_vm._v(_vm._s(_vm.buttonText))]),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.cancelDelete}},[_vm._v("Cancel")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }